/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-08-01 15:29:04
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-01 15:44:14
 * @FilePath: /aurora-carton-web/src/views/outStock/history/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//设置表头配置
export const customMade = {
  200:{
    columns : [
      {
        title:"订单编号",
        fieldKey:"orderSn",
        width:200,
        isShow:true
      },
      {
        title: "客户/供应商名称",
        fieldKey:"thirdName",
        width:'300',
        isShow:true
      },
      {
        title:"产品名称",
        fieldKey:"productName",
        width:'300',
        isShow:true
      },
      {
        title:"产品数量",
        fieldKey: "realityCount",
        width:200,
        isShow:true,
        sortable:true
      },
      {
        title:"产品金额(元)",
        fieldKey: "realityTotalPrice",
        width:200,
        isShow:true,
        sortable:true
      },
      {
        title:"出库类型",
        fieldKey:"orderTypeDesc",
        width:100,
        isShow:true
      },
      {
        title:"出库仓库",
        fieldKey:"stockName",
        width:100,
        isShow:true
      },
      {
        title:"操作人",
        fieldKey:"userName",
        width:100,
        isShow:true
      },
      {
        title: "出库时间",
        fieldKey:"time",
        width:200,
        isShow:true,
        sortable:true
      },
      {
        title:"备注",
        fieldKey:"remark",
        width:'auto',
        isShow:true
      },
    ],
    inStock: false
  }
}