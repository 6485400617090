/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-08-01 15:39:35
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-01 15:57:04
 * @FilePath: /aurora-carton-web/src/views/outStock/history/util.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { ref} from 'vue';
import { customMade} from './config';
import { cloneDeep } from 'lodash';
export function getConfig(orderType){
  const columns = ref([])
  const inStock = ref(null)
  let obj = customMade[orderType]
  columns.value = cloneDeep(obj.columns)
  inStock.value = cloneDeep(obj.inStock)
  return{
    columns,
    inStock
  }
}
